import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0a2bfa7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stock" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "stock-title" }
const _hoisted_4 = {
  key: 0,
  class: "stock-price"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "stock-link"
}
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model.quantity > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.model.quantity) + " авто", 1),
          (_ctx.model.minPrice || _ctx.model.maxPrice)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                (_ctx.model.minPrice !== null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, "от " + _toDisplayString(_ctx.formatPrice(_ctx.model.minPrice)) + " BYN", 1))
                  : _createCommentVNode("", true),
                (_ctx.model.maxPrice !== null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "до " + _toDisplayString(_ctx.formatPrice(_ctx.model.maxPrice)) + " BYN", 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.model.url)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("a", {
                  href: _ctx.model.url,
                  target: "_blank"
                }, "Склад", 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("span", _hoisted_9, " - "))
  ]))
}