import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-62d00c9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-2" }
const _hoisted_2 = { class: "relations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_TableCellStock = _resolveComponent("TableCellStock")!
  const _component_count_button = _resolveComponent("count-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Модели" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.tagRemovalHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.brandFilter,
                  "onUpdate:selectedOptions": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.brandFilter) = $event)),
                  options: _ctx.brandOptionsList,
                  name: "brandFilter",
                  searchable: true,
                  label: "Бренд"
                }, null, 8, ["selected-options", "options"]),
                _createVNode(_component_FormFieldMultiSelect, {
                  "selected-options": _ctx.stockFilter,
                  "onUpdate:selectedOptions": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stockFilter) = $event)),
                  options: _ctx.stockOptionsList,
                  name: "stock",
                  label: "Наличие"
                }, null, 8, ["selected-options", "options"])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(stock-new)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockNew
          }, null, 8, ["model"])
        ]),
        "cell(stock-amp)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockAmp
          }, null, 8, ["model"])
        ]),
        "cell(stock-electric)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockElectric
          }, null, 8, ["model"])
        ]),
        "cell(stock-commercial)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockCommercial
          }, null, 8, ["model"])
        ]),
        "cell(stock-hybrid)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockHybrid
          }, null, 8, ["model"])
        ]),
        "cell(relations)": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getGenerationListUrl(),
              query: { 'filter[model]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_count_button, {
                  variant: "outline-secondary",
                  count: row.generationsCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Поколения ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getComplectationListUrl(),
              query: { 'filter[model]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_count_button, {
                  variant: "outline-secondary",
                  count: row.complectationsCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Комплектации ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }))
}