<template>
  <Page
    v-model:tab-id="selectedTabId"
    :title="pageTitle"
    :is-content-loading="isContentLoading"
    :tabs="isContentLoading ? [] : tabList"
  >
    <form v-if="!isContentLoading && brand" novalidate @submit.prevent>
      <div :style="{ display: selectedTabId === 'general' ? 'block' : 'none' }">
        <div class="row-cols-3">
          <FieldValue label="ID" :value="String(brand.id)" />
          <FieldValue label="API ID" :value="String(brand.apiId)" />
          <FieldValue label="Имя" :value="brand.name" />
        </div>

        <FieldValue label="Дилеры" type="list" :value="brand.dealers" />

        <FormFieldCheckbox
          v-model:checked="values.showInCatalog"
          name="showInCatalog"
          label="Показывать в каталоге"
          :error="errors.showInCatalog"
        />

        <FormField
          v-model:value="values.displayName"
          name="displayName"
          label="Отображаемое имя"
          :error="errors.displayName"
        />

        <FormField
          v-model:value="values.priority"
          name="priority"
          label="Приоритет"
          :error="errors.priority"
        />

        <FormFieldSelect
          v-model:value="values.kufarId"
          :options="kufarOptions"
          label="Kufar"
          :error="errors.kufarId"
        />
      </div>

      <div :style="{ display: selectedTabId === 'web' ? 'block' : 'none' }">
        <FieldValue
          label="Веб-страница"
          type="link"
          :src="model.catalogUrl"
          :value="model.catalogUrl"
        />

        <FormFieldFileInput
          v-model:value="values.logo"
          :error="errors.logoFile"
          label="Логотип"
          name="logoFile"
          file-type="image"
          scenario="logo-s3"
        />

        <FormFieldFileInput
          v-model:value="values.cover"
          :error="errors.coverFile"
          label="Обложка"
          name="coverFile"
          file-type="image"
          scenario="cover-s3"
        />

        <FormField
          v-model:value="values.title"
          name="title"
          label="Заголовок"
          :error="errors.title"
        />
        <FormField
          v-model:value="values.textShort"
          name="textShort"
          label="Текст - Краткий"
          :error="errors.textShort"
          type="textarea"
        />
        <FormField
          v-model:value="values.textFull"
          name="textFull"
          label="Текст - Полный"
          :error="errors.textFull"
          type="textarea"
        />
        <FormField
          v-model:value="values.warrantyText"
          name="warrantyText"
          label="Гарантия - Текст"
          :error="errors.warrantyText"
        />
      </div>
    </form>
    <template #footer>
      <FormFooter
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  isNotNullish,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormFieldSelect,
  FormFieldCheckbox,
  FormField,
  FieldValue,
  OptionType,
  FormFieldFileInput,
  TabType,
  createTabErrorFinder,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { BrandFullInterface, KufarBrand } from "@/typings/model";
import { getBrand, getKufarData, updateBrand } from "@/services/requests";
import {
  getBrandListUrl,
  getGenerationFormUrl,
  getGenerationListUrl,
} from "@/utils/paths";

import {
  FormValues,
  convertBrandToFormValues,
  convertFormValuesToBrandUpdatePayload,
} from "./BrandForm.helpers";

export default defineComponent({
  name: "BrandForm",
  components: {
    Page,
    FormFooter,
    FormFieldSelect,
    FormField,
    FieldValue,
    FormFieldCheckbox,
    FormFieldFileInput,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const brandId = computed<string>(() => route.params.brandId as string);

    const [fetchBrand, { data: brand, loading: isGenerationLoading }] =
      useResource<Nullable<BrandFullInterface>>({
        fetchResource: () => getBrand(brandId.value),
        initialValue: null,
        resourceName: "Бренд",
      });

    const [fetchKufarData, { loading: kufarLoading, data: kufarData }] =
      useResource<KufarBrand[]>({
        fetchResource: () => getKufarData(),
        initialValue: [],
        resourceName: "Список Kufar",
      });

    onMounted(() => {
      fetchBrand();
      fetchKufarData();
    });

    const kufarOptions = computed<Array<OptionType<string>>>(() =>
      kufarData.value.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })
    );

    const values = ref<FormValues>(
      convertBrandToFormValues(brand.value, kufarOptions.value)
    );

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch([brand, kufarOptions], () => {
      values.value = convertBrandToFormValues(brand.value, kufarOptions.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const updateBody = convertFormValuesToBrandUpdatePayload(
        values.value,
        kufarOptions.value
      );
      const requestPromise = updateBrand(brandId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(
              getGenerationFormUrl({ generationId: response.data.id })
            );
          }
          if (event.type === "create_exit" || event.type === "save_exit") {
            router.push(getBrandListUrl());
          }
          if (event.type === "create_create-another") {
            values.value = convertBrandToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: "Бренд успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка при обновлении поколения",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => isGenerationLoading.value || kufarLoading.value
    );

    const pageTitle = computed<string>(() => {
      return `Редактирование бренда ${
        brand.value?.name ? '"' + brand.value.name + '"' : ""
      }`;
    });

    const boolToString = (bool: boolean): string => {
      return bool ? "Да" : "Нет";
    };

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);
      return [
        {
          id: "general",
          label: "Основное",
          hasErrors: hasErrors([]),
        },
        {
          id: "web",
          label: "Web",
          hasErrors: hasErrors([]),
        },
      ];
    });
    const selectedTabId = ref<string>(tabList.value[0].id);

    return {
      tabList,
      selectedTabId,

      pageTitle,
      isContentLoading,
      values,
      model: brand,
      errors,
      brand,

      kufarOptions,

      submitForm,
      backButtonUrl: getGenerationListUrl(),
      isSubmitting,
      boolToString,
    };
  },
});
</script>

<style scoped lang="scss">
.info {
  display: flex;

  div {
    margin-right: 3rem;
  }
}
</style>
