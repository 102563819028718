<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <template v-if="isCustom">
        <div class="row-cols-3">
          <FormFieldSelect
            v-model:value="values.brand"
            name="brand"
            label="Бренд"
            :options="brandOptions"
            :disabled="isBrandsLoading"
            :error="errors.brand"
          />
          <FormFieldSelect
            v-model:value="values.model"
            name="model"
            label="Модель"
            :options="modelOptions"
            :disabled="isModelsLoading || !values.brand"
            :error="errors.model"
            :clearable="false"
          />
          <FormFieldSelect
            v-model:value="values.generation"
            name="generation"
            label="Поколение"
            :options="generationOptions"
            :disabled="isGenerationsLoading || !values.brand || !values.model"
            :error="errors.generation"
          />
        </div>

        <FormField
          v-model:value="values.name"
          name="name"
          :error="errors.name"
          label="Название"
        />
      </template>
      <template v-else-if="model">
        <div class="row-cols-4">
          <FieldValue label="API ID" :value="String(model.apiId)" />
          <FieldValue label="Бренд" :value="String(model.brand.name)" />
          <FieldValue
            label="Модель"
            :value="`${model.model.name} (ID ${model.model.id})`"
          />
          <FieldValue
            label="Поколение"
            :value="`${model.generation.name} (ID ${model.generation.id})`"
          />
        </div>
        <FieldValue label="Название" :value="values.name" />
      </template>
      <FormField
        v-model:value="values.subtitle"
        name="subtitle"
        :error="errors.subtitle"
        label="Подзаголовок"
      />
      <FormFieldFileInput
        v-model:value="values.image"
        :error="errors.image"
        label="Картинка (245 x 180)"
        name="image"
        file-type="image"
        scenario="complectation"
      />
      <DynamicField v-if="isCustom" :field="itemsField" />
      <template v-else-if="model">
        <FieldValue
          v-for="item in model.categories"
          :key="item"
          :label="item.name"
          type="list"
          :value="
            item.items.map(
              (item) =>
                item.param +
                ': ' +
                (item.value
                  ? item.value + (item.hint ? ' (' + item.hint + ')' : '')
                  : '')
            )
          "
        />
      </template>
    </form>
    <template #footer>
      <FormFooter
        v-if="!isContentLoading"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FieldValue,
  FormFieldFileInput,
  FormFooter,
  TagerFormSubmitEvent,
  FormFieldSelect,
  OptionType,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  getComplectationListUrl,
  getComplectationUpdateUrl,
} from "@/utils/paths";
import {
  ComplectationCustomSavePayload,
  ComplectationSavePayload,
  createComplectation,
  getComplectation,
  getComplectationCatalog,
  updateComplectation,
} from "@/services/requests";
import {
  COMPLECTATION_ITEMS_CONFIG,
  convertFormValuesToCustomSavePayload,
  convertFormValuesToSavePayload,
  FormValues,
  getComplectationFormValues,
} from "@/views/ComplectationForm/ComplectationForm.helpers";

export default defineComponent({
  name: "ComplectationForm",
  components: {
    DynamicField,
    FormFieldFileInput,
    FormField,
    FieldValue,
    Page,
    FormFooter,
    FormFieldSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isSubmitting = ref<boolean>(false);
    const isModelCatalogLoading = ref<boolean>(false);

    const id = computed(() => route.params.id as string | undefined);

    const errors = ref<Record<string, string>>({});
    const isCreation = computed(() => id.value === "create");

    const values = ref<FormValues>(getComplectationFormValues(null));

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getComplectation(+id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Complectation",
    });

    const [fetchBrands, { data: brands, loading: isBrandsLoading }] =
      useResource({
        fetchResource: () => getComplectationCatalog({}),
        initialValue: null,
        resourceName: "Brands ",
      });

    const [fetchModels, { data: models, loading: isModelsLoading }] =
      useResource({
        fetchResource: (brand: number) => getComplectationCatalog({ brand }),
        initialValue: null,
        resourceName: "Models",
      });

    const [
      fetchGenerations,
      { data: generations, loading: isGenerationsLoading },
    ] = useResource({
      fetchResource: (model: number) => getComplectationCatalog({ model }),
      initialValue: null,
      resourceName: "Generations",
    });

    onMounted(() => {
      fetchModel();
      fetchBrands();
    });

    function updateItemsField() {
      const incomingFieldList: RepeaterIncomingValue = model.value?.categories
        ? model.value?.categories.map((value) => {
            return [
              {
                name: "category",
                value: value.name,
              },
              {
                name: "items",
                value:
                  value.items?.map((value) => {
                    return [
                      {
                        name: "param",
                        value: value.param,
                      },
                      {
                        name: "value",
                        value: value.value,
                      },
                      {
                        name: "hint",
                        value: value.hint,
                      },
                    ];
                  }) || [],
              },
            ];
          })
        : [];

      itemsField.value = universalFieldUtils.createFormField(
        COMPLECTATION_ITEMS_CONFIG,
        incomingFieldList
      ) as RepeaterField;
    }

    watch(model, () => updateItemsField());

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      let payload = {};
      if (isCustom.value) {
        payload = convertFormValuesToCustomSavePayload(
          values.value,
          itemsField.value as RepeaterField
        );
      } else {
        payload = convertFormValuesToSavePayload(values.value);
      }

      const requestPromise = isCreation.value
        ? createComplectation(payload as ComplectationCustomSavePayload)
        : updateComplectation(
            parseInt(id?.value || ""),
            payload as ComplectationSavePayload
          );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getComplectationUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getComplectationListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getComplectationFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Локации",
            body: isCreation.value
              ? "Комплектация успешно создана"
              : "Комплектация успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Локации",
            body: isCreation.value
              ? "Ошибка добавления комплектации"
              : "Ошибка изменения комплектации",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => Boolean(isModelLoading.value) || isModelCatalogLoading.value
    );

    const pageTitle = computed<string>(() => {
      if (isContentLoading.value) {
        return "Загрузка...";
      }
      if (isCreation.value) {
        return "Новая комплектация";
      }
      return `Редактирование комплектации # ${model.value?.id}`;
    });
    const itemsField = ref<FieldUnion>(
      universalFieldUtils.createFormField(COMPLECTATION_ITEMS_CONFIG, [])
    );

    const isCustom = computed<boolean>(
      () => isCreation.value || !model.value?.apiId
    );

    const brandId = computed<number | null>(
      () => values.value.brand?.value || null
    );

    watch(brandId, () => {
      brandId.value && fetchModels(brandId.value);
      values.value.model = null;
    });

    const modelId = computed<number | null>(
      () => values.value.model?.value || null
    );

    watch(modelId, () => {
      modelId.value && fetchGenerations(modelId.value);
      values.value.generation = null;
    });

    const brandOptions = computed<Array<OptionType<number>>>(() => {
      return (
        brands.value?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      );
    });

    const modelOptions = computed<Array<OptionType<number>>>(() => {
      return (
        models.value?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      );
    });

    const generationOptions = computed<Array<OptionType<number>>>(() => {
      return (
        generations.value?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      );
    });

    watch(model, () => {
      values.value = getComplectationFormValues(model.value);
      isModelCatalogLoading.value = true;
      if (model.value) {
        getComplectationCatalog({}).then((brands) => {
          const foundBrand = brands.data.find(
            (item) => item.id === model.value?.brandId
          );

          if (!foundBrand) {
            isModelCatalogLoading.value = false;
            return;
          }

          values.value.brand = {
            value: foundBrand.id,
            label: foundBrand.name,
          };

          getComplectationCatalog({ brand: foundBrand.id }).then((models) => {
            const foundModel = models.data.find(
              (item) => item.id === model.value?.modelId
            );

            if (!foundModel) {
              isModelCatalogLoading.value = false;
              return;
            }

            values.value.model = {
              value: foundModel.id,
              label: foundModel.name,
            };

            getComplectationCatalog({ model: foundModel.id }).then(
              (generations) => {
                const foundGeneration = generations.data.find(
                  (item) => item.id === model.value?.generationId
                );

                if (foundGeneration) {
                  values.value.generation = {
                    value: foundGeneration.id,
                    label: foundGeneration.name,
                  };
                }

                isModelCatalogLoading.value = false;
              }
            );
          });
        });
      }
    });

    return {
      submitForm,
      pageTitle,
      isContentLoading,
      model,

      backButtonUrl: getComplectationListUrl(),
      isSubmitting,

      errors,
      values,

      itemsField,
      isCustom,

      brandOptions,
      isBrandsLoading,
      modelOptions,
      isModelsLoading,
      generationOptions,
      isGenerationsLoading,
    };
  },
});
</script>

<style scoped lang="scss">
.info {
  display: flex;

  div {
    margin-right: 3rem;
  }
}

.row-inputs {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
</style>
