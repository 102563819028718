<template>
  <NavigationGrid :nav-items="navItemList" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGridItem, NavigationGrid } from "@tager/admin-ui";
import { useI18n, useResource } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import {
  getBrandCount,
  getComplectationCount,
  getGenerationCount,
  getModelCount,
} from "@/services/requests";

export default defineComponent({
  name: "HomePage",
  components: {
    NavigationGrid,
  },
  setup() {
    const { t } = useI18n();

    const links = computed(() => getLinks(t));

    const [
      fetchBrandCount,
      { data: brandCountData, status: brandCountDataStatus },
    ] = useResource({
      fetchResource: getBrandCount,
      resourceName: "Количество брендов",
      initialValue: null,
    });

    const [
      fetchModelCount,
      { data: modelCountData, status: modelCountDataStatus },
    ] = useResource({
      fetchResource: getModelCount,
      resourceName: "Количество моделей",
      initialValue: null,
    });

    const [
      fetchGenerationCount,
      { data: generationCountData, status: generationCountDataStatus },
    ] = useResource({
      fetchResource: getGenerationCount,
      resourceName: "Количество поколений",
      initialValue: null,
    });

    const [
      fetchComplectationCount,
      { data: complectationCountData, status: complectationCountDataStatus },
    ] = useResource({
      fetchResource: getComplectationCount,
      resourceName: "Количество комплектаций",
      initialValue: null,
    });

    onMounted(() => {
      fetchBrandCount();
      fetchModelCount();
      fetchGenerationCount();
      fetchComplectationCount();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.BRAND_LIST.text,
          url: links.value.BRAND_LIST.url,
          total: {
            status: brandCountDataStatus.value,
            value: brandCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.MODEL_LIST.text,
          url: links.value.MODEL_LIST.url,
          total: {
            status: modelCountDataStatus.value,
            value: modelCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.GENERATION_LIST.text,
          url: links.value.GENERATION_LIST.url,
          total: {
            status: generationCountDataStatus.value,
            value: generationCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.COMPLECTATION_LIST.text,
          url: links.value.COMPLECTATION_LIST.url,
          total: {
            status: complectationCountDataStatus.value,
            value: complectationCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: "Команды",
          linkList: [
            links.value.CRON_COMMANDS,
            links.value.CRON_COMMANDS_LOGS,
            links.value.CRON_LOGS,
          ],
          width: 50,
        },
        {
          name: "Администраторы",
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
</script>

<style scoped></style>
