import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { FullGenerationInterface } from "@/typings/model";
import { GenerationCreateOrUpdatePayload } from "@/services/requests";
import { GenerationLandingValue } from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export interface FormValues {
  isNew: boolean;

  brand: Nullable<OptionType<number>>;
  model: Nullable<OptionType<number>>;
  kufarId: Nullable<OptionType<string>>;
  name: string;
  slug: string;
  badge: string;
  badgeHint: string;
  isPublic: boolean;

  paramBody: Array<OptionType<string>>;
  paramGearbox: Array<OptionType<string>>;
  paramWheel: Array<OptionType<string>>;
  paramFuel: Array<OptionType<string>>;
  paramBattery: Array<OptionType<string>>;
  paramColor: Array<OptionType<number>>;
  paramVolumeFrom: number | null;
  paramVolumeTo: number | null;
  paramSeatsFrom: number | null;
  paramSeatsTo: number | null;
  paramElectricDistanceFrom: number | null;
  paramElectricDistanceTo: number | null;
  paramElectricBatteryCapacityFrom: number | null;
  paramElectricBatteryCapacityTo: number | null;

  publicUrl: Nullable<string>;
  reviewUrl: Nullable<string>;
  pricesHidden: boolean;
  vinNewHidden: boolean;
  deliveryDisabled: boolean;
  saleDisabled: boolean;
  saleDisabledText: Nullable<string>;
}

export function convertGenerationToFormValues(
  generation: Nullable<FullGenerationInterface>,
  brands: Nullable<Array<OptionType<number>>>,
  models: Nullable<Array<OptionType<number>>>,
  kufarOptions: Nullable<Array<OptionType<string>>>,
  bodyOptions: Array<OptionType<string>>,
  gearboxOptions: Array<OptionType<string>>,
  fuelOptions: Array<OptionType<string>>,
  wheelOptions: Array<OptionType<string>>,
  batteryOptions: Array<OptionType<string>>,
  colorOptions: Array<OptionType<number>>
): FormValues {
  return {
    isNew: generation?.isNew || false,

    slug: generation?.slug || "",

    brand: brands?.find((item) => item.value === generation?.brand?.id) || null,
    model: models?.find((item) => item.value === generation?.model?.id) || null,
    kufarId:
      kufarOptions?.find((item) => item.value === generation?.kufar?.id) ||
      null,
    name: generation?.name ?? "",
    badge: generation?.badge ?? "",
    badgeHint: generation?.badgeHint ?? "",

    paramBody: bodyOptions?.filter((item) =>
      generation?.params.body?.includes(item.value)
    ),
    paramGearbox: gearboxOptions?.filter((item) =>
      generation?.params.gearbox?.includes(item.value)
    ),
    paramFuel: fuelOptions?.filter((item) =>
      generation?.params.fuel?.includes(item.value)
    ),
    paramWheel: wheelOptions?.filter((item) =>
      generation?.params.wheel?.includes(item.value)
    ),
    paramBattery: batteryOptions?.filter((item) =>
      generation?.params.battery?.includes(item.value)
    ),
    paramColor: colorOptions?.filter((item) =>
      generation?.params.color?.includes(String(item.value))
    ),

    paramVolumeFrom: generation?.params.volumeFrom || null,
    paramVolumeTo: generation?.params.volumeTo || null,
    paramSeatsFrom: generation?.params.seatsFrom || null,
    paramSeatsTo: generation?.params.seatsTo || null,
    paramElectricDistanceFrom: generation?.params.electricDistanceFrom || null,
    paramElectricDistanceTo: generation?.params.electricDistanceTo || null,
    paramElectricBatteryCapacityFrom:
      generation?.params.electricBatteryCapacityFrom || null,
    paramElectricBatteryCapacityTo:
      generation?.params.electricBatteryCapacityTo || null,

    isPublic: Boolean(generation?.isPublic) ?? false,
    publicUrl: generation?.publicUrl ?? "",
    reviewUrl: generation?.reviewUrl ?? "",
    pricesHidden: Boolean(generation?.pricesHidden) ?? false,
    vinNewHidden: Boolean(generation?.vinNewHidden) ?? false,
    deliveryDisabled: Boolean(generation?.deliveryDisabled) ?? false,

    saleDisabled: Boolean(generation?.saleDisabled) ?? false,
    saleDisabledText: generation?.saleDisabledText ?? "",
  };
}

export function convertFormValuesToGenerationUpdatePayload(
  values: FormValues,
  imagesField: RepeaterField,
  galleryField: RepeaterField,
  landing: GenerationLandingValue
): GenerationCreateOrUpdatePayload {
  return {
    isNew: values.isNew,
    model: values.model?.value || null,
    name: values.name || null,
    slug: values.slug || null,

    images: imagesField.value.map((row) => {
      return {
        color: Number((row.value[0]?.value as OptionType).value) || null,
        images:
          (row.value[1]?.value as any[]).map((item) => {
            return item.file.id;
          }) || [],
      };
    }),

    galleryItems: galleryField.value.map((row) => {
      return {
        category: String(row.value[0].value),
        file: row.value[1].value
          ? (row.value[1].value as any).file?.id || null
          : null,
        videoUrl: String(row.value[2].value),
      };
    }),

    badge: values.badge,
    badgeHint: values.badgeHint,
    isPublic: values.isPublic,
    publicUrl: values.isPublic ? values.publicUrl : null,
    reviewUrl: values.reviewUrl,
    pricesHidden: values.pricesHidden,
    vinNewHidden: values.vinNewHidden,
    deliveryDisabled: values.deliveryDisabled,
    kufarId: values.kufarId?.value || null,
    kufarName: values.kufarId?.label || null,
    saleDisabled: values.saleDisabled,
    saleDisabledText: values.saleDisabled ? values.saleDisabledText : null,

    landing,

    paramBody: values.paramBody.map((item) => item.value),
    paramGearbox: values.paramGearbox.map((item) => item.value),
    paramFuel: values.paramFuel.map((item) => item.value),
    paramWheel: values.paramWheel.map((item) => item.value),
    paramBattery: values.paramBattery.map((item) => item.value),
    paramColor: values.paramColor.map((item) => item.value),
    paramVolumeFrom: values.paramVolumeFrom,
    paramVolumeTo: values.paramVolumeTo,
    paramSeatsFrom: values.paramSeatsFrom,
    paramSeatsTo: values.paramSeatsTo,
    paramElectricDistanceFrom: values.paramElectricDistanceFrom,
    paramElectricDistanceTo: values.paramElectricDistanceTo,
    paramElectricBatteryCapacityFrom: values.paramElectricBatteryCapacityFrom,
    paramElectricBatteryCapacityTo: values.paramElectricBatteryCapacityTo,
  };
}

export function getGenerationImagesFieldConfig(
  colorOptions: Array<OptionType<number>>
): FieldConfigUnion {
  return {
    name: "",
    type: "REPEATER",
    label: "Картинки",
    meta: {
      view: "BLOCK",
      noToggle: true,
      titleFormatter: (fields) => {
        return fields[0].value
          ? (fields[0].value as OptionType).label
          : "Без цвета";
      },
    },
    fields: [
      {
        name: "color",
        type: "SELECT",
        label: "Цвет",
        meta: {
          options: [{ value: "", label: "Без цвета" }, ...colorOptions],
        },
      },
      {
        name: "images",
        type: "GALLERY",
        label: "Картинки",
        meta: {
          scenario: "generation-image-s3",
        },
      },
    ],
  };
}

export const GENERATION_GALLERY_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Галерея",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "category",
      type: "STRING",
      label: "Категория",
    },
    {
      name: "image",
      type: "FILE",
      label: "Картинка",
      meta: {
        scenario: "generation-image-s3",
      },
    },
    {
      name: "videoUrl",
      type: "URL",
      label: "Ссылка на Видео",
    },
  ],
};
