import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type OpinionsLandingFormValues = {
  opinionsTitle?: string;
  opinionsText?: string;
};

export const convertOpinionsValueToFormValues = (
  data: any
): OpinionsLandingFormValues => ({
  opinionsTitle: data.opinionsTitle,
  opinionsText: data.opinionsText,
});

export type OpinionsLandingValue = OpinionsLandingFormValues & {
  opinionsItems?: Array<{
    title: string;
    text: string;
    link: string;
    image: string | null;
    video: string | null;
    videoUrl: string | null;
  }>;
};

export const convertOpinionsFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  opinionsItemsField: RepeaterField
): OpinionsLandingValue => ({
  opinionsTitle: formValues.opinionsTitle,
  opinionsText: formValues.opinionsText,
  opinionsItems: opinionsItemsField.value.map((item) => ({
    title: String(item.value[0].value),
    text: String(item.value[1].value),
    link: String(item.value[2].value),
    image: item.value[3].value ? (item.value[3].value as any).file.id : null,
    video: item.value[4].value ? (item.value[4].value as any).file.id : null,
    videoUrl: String(item.value[5].value),
  })),
});

export const OpinionsItemsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Элементы",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "TEXT",
      label: "Текст",
    },
    {
      name: "link",
      type: "URL",
      label: "Ссылка",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (810 x 450, 390 x 450)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "video",
      type: "FILE",
      label: "Видео - Файл",
      meta: {
        scenario: "generation-landing-video",
      },
    },
    {
      name: "videoUrl",
      type: "URL",
      label: "Видео - Ссылка",
    },
  ],
};

export const createOpinionsItemsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      OpinionsItemsConfig,
      data.opinionsItems?.map((item: any) => {
        return [
          {
            name: "title",
            value: item.title,
          },
          {
            name: "text",
            value: item.text,
          },
          {
            name: "link",
            value: item.link,
          },
          {
            name: "image",
            value: item.image,
          },
          {
            name: "video",
            value: item.video,
          },
          {
            name: "videoUrl",
            value: item.videoUrl,
          },
        ];
      }) || []
    )
  );
