import { ColumnDefinition } from "@tager/admin-ui";

import { ComplectationInterface } from "@/typings/model";
import { getComplectationUpdateUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<ComplectationInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 1,
    name: "API ID",
    field: "apiId",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getComplectationUpdateUrl(row.id),
      },
      paramList: [
        { name: "Бренд", value: row.brand },
        { name: "Модель", value: row.model },
        { name: "Поколение", value: row.generation },
      ],
    }),
  },
  {
    id: 3,
    name: "Картинка",
    field: "image",
    type: "image",
    width: "180px",
  },
  {
    id: 7,
    name: "Наличие",
    field: "stock",
    width: "180px",
  },
  {
    id: 8,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];
