export const ROUTE_PATHS = {
  BRAND_LIST: "/brands",
  BRAND_FORM: "/brands/:brandId",
  MODEL_LIST: "/models",
  MODEL_FORM: "/models/:modelId",
  COMPLECTATION_LIST: "/complectations",
  COMPLECTATION_FORM: "/complectations/:id",
  GENERATION_LIST: "/generations",
  GENERATION_FORM: "/generations/:generationId",
};
