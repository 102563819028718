import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-32c0d2fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading && _ctx.model)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_FieldValue, {
                label: "ID",
                value: String(_ctx.model.id)
              }, null, 8, ["value"]),
              _createVNode(_component_FieldValue, {
                label: "API ID",
                value: String(_ctx.model.apiId)
              }, null, 8, ["value"])
            ]),
            _createVNode(_component_FieldValue, {
              label: "Имя",
              value: _ctx.model.name
            }, null, 8, ["value"]),
            _createVNode(_component_FormFieldFileInput, {
              value: _ctx.values.image,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.image) = $event)),
              error: _ctx.errors.image,
              label: "Картинка",
              name: "image",
              "file-type": "image",
              scenario: "model-preview-s3"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.shortDescription,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.shortDescription) = $event)),
              name: "shortDescription",
              label: "Краткое описание",
              error: _ctx.errors.shortDescription,
              type: "textarea"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.description,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.description) = $event)),
              name: "description",
              label: "Описание",
              error: _ctx.errors.description,
              type: "textarea"
            }, null, 8, ["value", "error"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}