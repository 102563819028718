<template>
  <Page title="Бренды">
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #cell(relations)="{ row }">
        <div class="relations">
          <router-link
            :to="{
              path: getModelListUrl(),
              query: { 'filter[brand]': row.id },
            }"
          >
            <CountButton
              variant="outline-secondary"
              :count="row.modelsCount"
              class="margin-right"
            >
              Модели
            </CountButton>
          </router-link>
          <router-link
            :to="{
              path: getGenerationListUrl(),
              query: { 'filter[brand]': row.id },
            }"
          >
            <CountButton
              variant="outline-secondary"
              :count="row.generationsCount"
              class="margin-right"
            >
              Поколения
            </CountButton>
          </router-link>
          <router-link
            :to="{
              path: getComplectationListUrl(),
              query: { 'filter[brand]': row.id },
            }"
          >
            <CountButton
              variant="outline-secondary"
              :count="row.complectationsCount"
              class="margin-right"
            >
              Комплектации
            </CountButton>
          </router-link>
        </div>
      </template>

      <template #cell(stock-new)="{ row }">
        <TableCellStock :model="row.stockNew" />
      </template>

      <template #cell(stock-amp)="{ row }">
        <TableCellStock :model="row.stockAmp" />
      </template>

      <template #cell(stock-electric)="{ row }">
        <TableCellStock :model="row.stockElectric" />
      </template>

      <template #cell(stock-commercial)="{ row }">
        <TableCellStock :model="row.stockCommercial" />
      </template>

      <template #cell(stock-hybrid)="{ row }">
        <TableCellStock :model="row.stockHybrid" />
      </template>

      <template #cell(actions)="{ row }">
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy()"
          :href="getBrandFormUrl({ brandId: row.id })"
        >
          <EditIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  CountButton,
  BaseButton,
  EditIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getBrandList } from "@/services/requests";
import { BrandInterface } from "@/typings/model";
import {
  getModelListUrl,
  getGenerationListUrl,
  getComplectationListUrl,
  getBrandFormUrl,
} from "@/utils/paths";
import { COLUMN_DEFS } from "@/views/BrandList/BrandList.helpers";
import TableCellStock from "@/components/TableCellStock.vue";

export default defineComponent({
  name: "BrandList",
  components: {
    TableCellStock,
    DataTable,
    CountButton,
    Page,
    BaseButton,
    EditIcon,
  },
  setup() {
    const {
      isLoading: isBrandListDataLoading,
      rowData: brandListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<BrandInterface>({
      fetchEntityList: (params) =>
        getBrandList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список брендов",
    });

    function isBusy(): boolean {
      return isBrandListDataLoading.value;
    }

    const formatPrice = (num: number) =>
      String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ");

    return {
      columnDefs: COLUMN_DEFS,
      rowData: brandListData,
      isRowDataLoading: isBrandListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,
      formatPrice,
      getModelListUrl,
      getGenerationListUrl,
      getComplectationListUrl,
      getBrandFormUrl,
    };
  },
});
</script>

<style scoped lang="scss">
.relations {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:first-child) {
    margin-top: 10px;
  }
}

.links {
  > div {
    display: block;

    b {
      display: block;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  a {
    color: #007bff;
  }
}

.margin-right {
  margin-right: 12px;
}

.checkboxes {
  .form-group {
    margin-bottom: 1em;
  }

  > div:last-child .form-group {
    margin-bottom: 0;
  }
}
</style>
