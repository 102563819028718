import { createId, Nullable } from "@tager/admin-services";
import { SingleFileInputValueType } from "@tager/admin-ui";

import { ModelFullInterface } from "@/typings/model";
import { ModelUpdatePayload } from "@/services/requests";

export interface FormValues {
  image: Nullable<SingleFileInputValueType>;
  shortDescription: string;
  description: string;
}

export function convertModelToFormValues(
  model: ModelFullInterface | null
): FormValues {
  return {
    image: model?.image ? { id: createId(), file: model.image } : null,
    shortDescription: model?.shortDescription || "",
    description: model?.description || "",
  };
}

export function convertFormValuesToModelUpdatePayload(
  values: FormValues
): ModelUpdatePayload {
  return {
    description: values.description,
    shortDescription: values.shortDescription,
    image: values.image?.file?.id || null,
  };
}
