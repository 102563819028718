import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, withModifiers as _withModifiers, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-74b49e88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }
const _hoisted_2 = { class: "row-cols-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, [
            (_ctx.isCustom)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_FormFieldSelect, {
                      value: _ctx.values.brand,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.brand) = $event)),
                      name: "brand",
                      label: "Бренд",
                      options: _ctx.brandOptions,
                      disabled: _ctx.isBrandsLoading,
                      error: _ctx.errors.brand
                    }, null, 8, ["value", "options", "disabled", "error"]),
                    _createVNode(_component_FormFieldSelect, {
                      value: _ctx.values.model,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.model) = $event)),
                      name: "model",
                      label: "Модель",
                      options: _ctx.modelOptions,
                      disabled: _ctx.isModelsLoading || !_ctx.values.brand,
                      error: _ctx.errors.model,
                      clearable: false
                    }, null, 8, ["value", "options", "disabled", "error"]),
                    _createVNode(_component_FormFieldSelect, {
                      value: _ctx.values.generation,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.generation) = $event)),
                      name: "generation",
                      label: "Поколение",
                      options: _ctx.generationOptions,
                      disabled: _ctx.isGenerationsLoading || !_ctx.values.brand || !_ctx.values.model,
                      error: _ctx.errors.generation
                    }, null, 8, ["value", "options", "disabled", "error"])
                  ]),
                  _createVNode(_component_FormField, {
                    value: _ctx.values.name,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.name) = $event)),
                    name: "name",
                    error: _ctx.errors.name,
                    label: "Название"
                  }, null, 8, ["value", "error"])
                ], 64))
              : (_ctx.model)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_FieldValue, {
                        label: "API ID",
                        value: String(_ctx.model.apiId)
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, {
                        label: "Бренд",
                        value: String(_ctx.model.brand.name)
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, {
                        label: "Модель",
                        value: `${_ctx.model.model.name} (ID ${_ctx.model.model.id})`
                      }, null, 8, ["value"]),
                      _createVNode(_component_FieldValue, {
                        label: "Поколение",
                        value: `${_ctx.model.generation.name} (ID ${_ctx.model.generation.id})`
                      }, null, 8, ["value"])
                    ]),
                    _createVNode(_component_FieldValue, {
                      label: "Название",
                      value: _ctx.values.name
                    }, null, 8, ["value"])
                  ], 64))
                : _createCommentVNode("", true),
            _createVNode(_component_FormField, {
              value: _ctx.values.subtitle,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.subtitle) = $event)),
              name: "subtitle",
              error: _ctx.errors.subtitle,
              label: "Подзаголовок"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormFieldFileInput, {
              value: _ctx.values.image,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.image) = $event)),
              error: _ctx.errors.image,
              label: "Картинка (245 x 180)",
              name: "image",
              "file-type": "image",
              scenario: "complectation"
            }, null, 8, ["value", "error"]),
            (_ctx.isCustom)
              ? (_openBlock(), _createBlock(_component_DynamicField, {
                  key: 2,
                  field: _ctx.itemsField
                }, null, 8, ["field"]))
              : (_ctx.model)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.model.categories, (item) => {
                    return (_openBlock(), _createBlock(_component_FieldValue, {
                      key: item,
                      label: item.name,
                      type: "list",
                      value: 
            item.items.map(
              (item) =>
                item.param +
                ': ' +
                (item.value
                  ? item.value + (item.hint ? ' (' + item.hint + ')' : '')
                  : '')
            )
          
                    }, null, 8, ["label", "value"]))
                  }), 128))
                : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}