import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { GenerationInterface } from "@/typings/model";
import { getGenerationFormUrl } from "@/utils/paths";

export const stockFilterOptions: OptionType[] = [
  {
    label: "В наличии",
    value: "in_stock",
  },
  {
    label: "Нет в наличии",
    value: "no_stock",
  },
];
export const COLUMN_DEFS: Array<ColumnDefinition<GenerationInterface>> = [
  {
    id: 1,
    name: "API ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getGenerationFormUrl({ generationId: row.id }),
      },
      websiteLink: row.url
        ? {
            text: row.url,
            url: row.url,
          }
        : null,
      paramList: [
        { name: "Бренд", value: row.brand },
        { name: "Модель", value: row.model },
        {
          name: "Kufar",
          value: row.kufar ? `${row.kufar.id} (${row.kufar.name})` : "-",
        },
        { name: "Снят с продажи", value: row.saleDisabled ? "Да" : "Нет" },
        {
          name: "Не выводить VIN в NEW",
          value: row.vinNewHidden ? "Да" : "Нет",
        },
        {
          name: "Спрятать цены",
          value: row.pricesHidden ? "Да" : "Нет",
        },
      ],
    }),
  },
  {
    id: 3,
    name: "Наличие",
    field: "stock",
  },
  {
    id: 4,
    name: "",
    field: "relations",
    style: { width: "230px", minWidth: "230px", textAlign: "left" },
    headStyle: {
      width: "230px",
      minWidth: "230px",
      textAlign: "center",
      whiteSpace: "nowrap",
    },
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];
