import { onMounted } from "vue";

import { ResourceRef, useResource } from "@tager/admin-services";

import { BrandInterface } from "@/typings/model";
import { getBrandList } from "@/services/requests";

export function useFetchBrands(): ResourceRef<BrandInterface[]> {
  const [fetchBrands, resource] = useResource<BrandInterface[]>({
    fetchResource: () => getBrandList({ pageSize: 10000, pageNumber: 1 }),
    initialValue: [],
    resourceName: "Brands List",
  });

  onMounted(() => {
    fetchBrands();
  });

  return resource;
}
