import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { ModelInterface } from "@/typings/model";
import { getBrandFormUrl, getModelFormUrl } from "@/utils/paths";

export const stockFilterOptions: OptionType[] = [
  {
    label: "В наличии",
    value: "in_stock",
  },
  {
    label: "Нет в наличии",
    value: "no_stock",
  },
];

export const COLUMN_DEFS: Array<ColumnDefinition<ModelInterface>> = [
  {
    id: 1,
    name: "API ID",
    field: "apiId",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center" },
  },
  {
    id: 2,
    name: "Бренд",
    field: "brand",
  },

  {
    id: 2,
    name: "Модель",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getModelFormUrl({ modelId: row.id }),
      },
      paramList: [
        {
          name: "Kufar",
          value: row.kufar ? `${row.kufar.id} (${row.kufar.name})` : "-",
        },
      ],
    }),
  },
  {
    id: 4,
    name: "Склад - Новые",
    field: "stock-new",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 5,
    name: "Склад - АМП",
    field: "stock-amp",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "Склад - Электро",
    field: "stock-electric",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 7,
    name: "Склад - Коммерческие",
    field: "stock-commercial",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 8,
    name: "Склад - Гибриды",
    field: "stock-hybrid",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 9,
    name: "",
    field: "relations",
    style: { width: "230px", textAlign: "left" },
    headStyle: { width: "230px", textAlign: "center", whiteSpace: "nowrap" },
  },
];
