<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <form v-if="!isContentLoading && model" novalidate @submit.prevent>
      <div class="row-cols-3">
        <FieldValue label="ID" :value="String(model.id)" />
        <FieldValue label="API ID" :value="String(model.apiId)" />
      </div>
      <FieldValue label="Имя" :value="model.name" />

      <FormFieldFileInput
        v-model:value="values.image"
        :error="errors.image"
        label="Картинка"
        name="image"
        file-type="image"
        scenario="model-preview-s3"
      />

      <FormField
        v-model:value="values.shortDescription"
        name="shortDescription"
        label="Краткое описание"
        :error="errors.shortDescription"
        type="textarea"
      />
      <FormField
        v-model:value="values.description"
        name="description"
        label="Описание"
        :error="errors.description"
        type="textarea"
      />
    </form>
    <template #footer>
      <FormFooter
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FieldValue,
  FormField,
  FormFieldFileInput,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { ModelFullInterface } from "@/typings/model";
import { getModel, updateModel } from "@/services/requests";
import { getModelListUrl } from "@/utils/paths";

import {
  FormValues,
  convertModelToFormValues,
  convertFormValuesToModelUpdatePayload,
} from "./ModelForm.helpers";

export default defineComponent({
  name: "ModelForm",
  components: {
    FormFieldFileInput,
    FormField,
    Page,
    FormFooter,
    FieldValue,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const modelId = computed<string>(() => route.params.modelId as string);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource<
      Nullable<ModelFullInterface>
    >({
      fetchResource: () => getModel(modelId.value),
      initialValue: null,
      resourceName: "Модель",
    });

    onMounted(() => {
      fetchModel();
    });

    const values = ref<FormValues>(convertModelToFormValues(model.value));

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch([model], () => {
      values.value = convertModelToFormValues(model.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const updateBody = convertFormValuesToModelUpdatePayload(values.value);
      const requestPromise = updateModel(modelId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "save_exit") {
            router.push(getModelListUrl());
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: "Модель успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка при обновлении модели",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(() => isModelLoading.value);

    const pageTitle = computed<string>(() => {
      return `Редактирование модели ${
        model.value?.name ? '"' + model.value.name + '"' : ""
      }`;
    });

    const boolToString = (bool: boolean): string => {
      return bool ? "Да" : "Нет";
    };

    return {
      pageTitle,
      isContentLoading,
      values,
      model,
      errors,

      submitForm,
      backButtonUrl: getModelListUrl(),
      isSubmitting,
      boolToString,
    };
  },
});
</script>

<style scoped lang="scss">
.info {
  display: flex;

  div {
    margin-right: 3rem;
  }
}
</style>
