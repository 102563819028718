<template v-if="!isLoading">
  <FieldValue
    label="Тип кузова"
    :value="
      model.params.body
        ?.map(
          (item) => bodiesOptions.find((option) => option.value === item)?.label
        )
        .join(', ') || ''
    "
  />
  <FieldValue
    label="Коробка передач"
    :value="
      model.params.gearbox
        ?.map(
          (item) =>
            gearboxOptions.find((option) => option.value === item)?.label
        )
        .join(', ') || ''
    "
  />
  <FieldValue
    label="Привод"
    :value="
      model.params.wheel
        ?.map(
          (item) => wheelOptions.find((option) => option.value === item)?.label
        )
        .join(', ') || ''
    "
  />
  <FieldValue
    label="Двигатель"
    :value="
      model.params.fuel
        ?.map(
          (item) => fuelOptions.find((option) => option.value === item)?.label
        )
        .join(', ') || ''
    "
  />
  <FieldValue
    label="Цвет"
    :value="
      model.params.color
        ?.map(
          (item) => colorOptions.find((option) => option.value === item)?.label
        )
        .join(', ') || ''
    "
  />

  <FieldValue
    label="Объем двигателя"
    :value="`от ${model.params.volumeFrom} до ${model.params.volumeTo}`"
  />
  <FieldValue
    label="Количество мест"
    :value="`от ${model.params.seatsFrom} до ${model.params.seatsTo}`"
  />

  <template v-if="model.params.fuel.includes('ELECTRIC')">
    <hr />
    <FieldValue
      label="Тип батареи"
      :value="
        model.params.battery
          ?.map(
            (item) =>
              batteryOptions.find((option) => option.value === item)?.label
          )
          .join(', ') || ''
      "
    />
    <FieldValue
      label="Запас хода"
      :value="`от ${model.params.electricDistanceFrom} до ${model.params.electricDistanceTo}`"
    />
    <FieldValue
      label="Емкость батареи"
      :value="`от ${model.params.electricBatteryCapacityFrom} до ${model.params.electricBatteryCapacityTo}`"
    />
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType } from "vue";

import { FieldValue, OptionType } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { FullGenerationInterface } from "@/typings/model";
import {
  GenerationParamOption,
  getGenerationParamsOptions,
} from "@/services/requests";

export default defineComponent({
  name: "GenerationFormTechnicalParamsValues",
  components: {
    FieldValue,
  },
  props: {
    model: {
      type: Object as PropType<FullGenerationInterface>,
      required: true,
    },
  },
  setup() {
    const [
      fetchParamsOptions,
      { loading: paramsOptionsLoading, data: paramsOptions },
    ] = useResource<GenerationParamOption[]>({
      fetchResource: () => getGenerationParamsOptions(),
      initialValue: [],
      resourceName: "Опции параметров",
    });

    onMounted(() => {
      fetchParamsOptions();
    });

    const bodiesOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "BODY")?.options ||
        []
    );

    const gearboxOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "GEARBOX")
          ?.options || []
    );

    const wheelOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "WHEEL")?.options ||
        []
    );

    const fuelOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "FUEL")?.options ||
        []
    );

    const batteryOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "BATTERY")
          ?.options || []
    );

    const colorOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "COLOR")?.options ||
        []
    );

    const isLoading = computed<boolean>(() => paramsOptionsLoading.value);

    return {
      isLoading,
      bodiesOptions,
      gearboxOptions,
      wheelOptions,
      fuelOptions,
      batteryOptions,
      colorOptions,
    };
  },
});
</script>
