import { onMounted } from "vue";

import { ResourceRef, useResource } from "@tager/admin-services";

import { ColorInterface } from "@/typings/model";
import { getColorList } from "@/services/requests";

export function useFetchColors(): ResourceRef<ColorInterface[]> {
  const [fetchColors, resource] = useResource<ColorInterface[]>({
    fetchResource: () => getColorList(),
    initialValue: [],
    resourceName: "Colors List",
  });

  onMounted(() => {
    fetchColors();
  });

  return resource;
}
