import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { BrandFullInterface } from "@/typings/model";
import { BrandUpdatePayload } from "@/services/requests";

export interface FormValues {
  displayName: string | null;
  showInCatalog: boolean;
  priority: number | null;
  kufarId: Nullable<OptionType<string>>;

  logo: Nullable<SingleFileInputValueType>;
  cover: Nullable<SingleFileInputValueType>;
  title: string;
  textShort: string;
  textFull: string;
  warrantyText: string;
}

export function convertBrandToFormValues(
  brand: BrandFullInterface | null,
  kufarOptions: Array<OptionType<string>> = []
): FormValues {
  return {
    displayName: brand ? brand.displayName : null,
    showInCatalog: brand ? brand.showInCatalog : false,
    priority: brand ? brand.priority : null,
    kufarId:
      kufarOptions.find((item) => item.value === brand?.kufar?.id) || null,

    logo: brand?.logo ? { id: createId(), file: brand.logo } : null,
    cover: brand?.cover ? { id: createId(), file: brand.cover } : null,

    title: brand?.title || "",
    textShort: brand?.textShort || "",
    textFull: brand?.textFull || "",
    warrantyText: brand?.warrantyText || "",
  };
}

export function convertFormValuesToBrandUpdatePayload(
  values: FormValues,
  kufarOptions: Array<OptionType<string>>
): BrandUpdatePayload {
  return {
    displayName: values.displayName,
    showInCatalog: values.showInCatalog,
    priority: values.priority || 0,
    kufarId: values.kufarId
      ? kufarOptions.find((item) => item.value === values.kufarId?.value)
          ?.value || null
      : null,
    kufarName: values.kufarId
      ? kufarOptions.find((item) => item.value === values.kufarId?.value)
          ?.label || null
      : null,

    logo: values.logo?.file?.id || null,
    cover: values.cover?.file?.id || null,

    title: values.title,
    textShort: values.textShort,
    textFull: values.textFull,
    warrantyText: values.warrantyText,
  };
}
