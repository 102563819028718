import { onMounted } from "vue";

import { ResourceRef, useResource } from "@tager/admin-services";

import { ModelInterface } from "@/typings/model";
import { getModelList } from "@/services/requests";

export function useFetchModels(): ResourceRef<ModelInterface[]> {
  const [fetchModels, resource] = useResource<ModelInterface[]>({
    fetchResource: () => getModelList({ pageSize: 10000, pageNumber: 1 }),
    initialValue: [],
    resourceName: "Models List",
  });

  onMounted(() => {
    fetchModels();
  });

  return resource;
}
