import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type InteriorLandingFormValues = {
  interiorTitle?: string;
  interiorTitle2?: string;
  interiorText?: string;
};

export const convertInteriorValueToFormValues = (
  data: any
): InteriorLandingFormValues => ({
  interiorTitle: data.interiorTitle,
  interiorTitle2: data.interiorTitle2,
  interiorText: data.interiorText,
});

export type InteriorLandingValue = {
  interiorTitle?: string;
  interiorTitle2?: string;
  interiorText?: string;
  interiorGallery?: Array<{
    image: string | null;
    video: string | null;
  }>;
  interiorFeatures?: Array<{
    name: string;
    image: string | null;
    video: string | null;
  }>;
};

export const convertInteriorFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  interiorGalleryField: RepeaterField,
  interiorFeaturesField: RepeaterField
): InteriorLandingValue => ({
  interiorTitle: formValues.interiorTitle || "",
  interiorTitle2: formValues.interiorTitle2 || "",
  interiorText: formValues.interiorText || "",
  interiorGallery: interiorGalleryField.value.map((item) => ({
    image: item.value[0].value ? (item.value[0].value as any).file.id : null,
    video: item.value[1].value ? (item.value[1].value as any).file.id : null,
  })),
  interiorFeatures: interiorFeaturesField.value.map((item) => ({
    name: String(item.value[0].value),
    image: item.value[1].value ? (item.value[1].value as any).file.id : null,
    video: item.value[2].value ? (item.value[2].value as any).file.id : null,
  })),
});

export const InteriorGalleryConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Галерея",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (1230 x 450)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "video",
      type: "FILE",
      label: "Видео",
      meta: {
        scenario: "generation-landing-video",
      },
    },
  ],
};

export const createInteriorGalleryField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      InteriorGalleryConfig,
      data.interiorGallery?.map((item: any) => {
        return [
          {
            name: "image",
            value: item.image,
          },
          {
            name: "video",
            value: item.video,
          },
        ];
      }) || []
    )
  );

export const InteriorFeaturesConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Преимущества",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "name",
      type: "STRING",
      label: "Название",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (390 x 260)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "video",
      type: "FILE",
      label: "Видео",
      meta: {
        scenario: "generation-landing-video",
      },
    },
  ],
};

export const createInteriorFeaturesField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      InteriorFeaturesConfig,
      data.interiorFeatures?.map((item: any) => {
        return [
          {
            name: "name",
            value: item.name,
          },
          {
            name: "image",
            value: item.image,
          },
          {
            name: "video",
            value: item.video,
          },
        ];
      }) || []
    )
  );
