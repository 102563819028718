import { computed, ComputedRef, Ref, ref, watch } from "vue";
import { useRoute } from "vue-router";

import {
  FilterTagType,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
} from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { BrandInterface, ModelInterface } from "@/typings/model";
import { stockFilterOptions } from "@/views/GenerationList/GenerationList.helpers";

interface Params {
  brandList: Ref<BrandInterface[]>;
  modelList: Ref<ModelInterface[]>;
}

interface State {
  brandFilter: Ref<OptionType[]>;
  brandOptionsList: ComputedRef<OptionType[]>;
  modelFilter: Ref<OptionType[]>;
  modelOptionsList: ComputedRef<OptionType[]>;
  statusFilter: Ref<OptionType[]>;
  statusOptionsList: OptionType[];
  stockFilter: Ref<OptionType[]>;
  stockOptionsList: OptionType[];

  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;

  tagRemovalHandler(event: FilterTagType): void;
}

enum FilterTypes {
  BRAND = "brand",
  MODEL = "model",
  STATUS = "status",
  STOCK = "stock",
}

export function useAdvancedSearch({ brandList, modelList }: Params): State {
  const route = useRoute();

  /** Brand **/
  const brandOptionsList = computed<OptionType[]>(() =>
    brandList.value.map(({ id, name }) => ({
      value: String(id),
      label: name,
    }))
  );

  const initialBrandFilter = computed<OptionType[]>(() => {
    const queryValue = getFilterParamAsStringArray(
      route.query,
      FilterTypes.BRAND
    );

    return brandOptionsList.value.filter(({ value }) =>
      queryValue.some((selected) => selected === value)
    );
  });

  const brandFilter = ref<OptionType[]>(initialBrandFilter.value);

  watch(initialBrandFilter, () => {
    brandFilter.value = initialBrandFilter.value;
  });

  /** Model **/
  const modelOptionsList = computed<OptionType[]>(() =>
    modelList.value.map(({ id, name, brand }) => ({
      value: String(id),
      label: brand + " - " + name,
    }))
  );

  const filteredModelList = computed<OptionType[]>(() => {
    if (brandFilter.value.length === 0) {
      return modelOptionsList.value;
    }

    const selectedList = modelList.value.filter(({ brandId }) =>
      brandFilter.value.some(({ value }) => +value === brandId)
    );

    return selectedList.map(({ id, name, brand }) => ({
      value: String(id),
      label: brand + " - " + name,
    }));
  });

  const initialModelFilter = computed<OptionType[]>(() => {
    const queryValue = getFilterParamAsStringArray(
      route.query,
      FilterTypes.MODEL
    );

    return modelOptionsList.value.filter(({ value }) => {
      return (
        queryValue.some((selected) => selected === value) &&
        filteredModelList.value.some((selected) => selected.value === value)
      );
    });
  });

  const modelFilter = ref<OptionType[]>(initialModelFilter.value);

  watch(initialModelFilter, () => {
    modelFilter.value = initialModelFilter.value;
  });

  /** Status **/
  const statusOptionsList: OptionType[] = [
    {
      value: "1",
      label: "Выводится на сайте",
    },
    {
      value: "0",
      label: "Не показывать на сайте",
    },
  ];

  const initialStatusFilter = computed<OptionType[]>(() => {
    const queryValue = getFilterParamAsStringArray(
      route.query,
      FilterTypes.STATUS
    );
    return statusOptionsList.filter(({ value }) => {
      return queryValue.some((selected) => selected === value);
    });
  });

  const statusFilter = ref<OptionType[]>(initialStatusFilter.value);

  watch(initialStatusFilter, () => {
    statusFilter.value = initialStatusFilter.value;
  });

  /** Stock **/

  const initialStockFilter = computed<OptionType[]>(() => {
    const queryValue = getFilterParamAsStringArray(
      route.query,
      FilterTypes.STOCK
    );

    return stockFilterOptions.filter(({ value }) =>
      queryValue.some((selected) => selected === value)
    );
  });

  const stockFilter = ref<OptionType[]>(initialStockFilter.value);

  watch(initialStockFilter, () => {
    stockFilter.value = initialStockFilter.value;
  });

  /** Params **/

  const filterParams = computed(() => {
    const filters: Record<string, string | string[]> = {
      [FilterTypes.BRAND]: brandFilter.value.map(({ value }) => value),
      [FilterTypes.MODEL]: modelFilter.value.map(({ value }) => value),
      [FilterTypes.STATUS]: statusFilter.value.map(({ value }) => value),
      [FilterTypes.STOCK]: stockFilter.value.map(({ value }) => value),
    };

    return getFilterParams(filters);
  });

  /** Tag removal handler **/

  function tagRemovalHandler(event: FilterTagType) {
    if (event.name === FilterTypes.BRAND) {
      brandFilter.value = brandFilter.value.filter(
        ({ value }) => value !== event.value
      );
    }
    if (event.name === FilterTypes.MODEL) {
      modelFilter.value = modelFilter.value.filter(
        ({ value }) => value !== event.value
      );
    }
    if (event.name === FilterTypes.STATUS) {
      statusFilter.value = statusFilter.value.filter(
        ({ value }) => value !== event.value
      );
    }
    if (event.name === FilterTypes.STOCK) {
      stockFilter.value = stockFilter.value.filter(
        ({ value }) => value !== event.value
      );
    }
  }

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      ...brandFilter.value.map(({ value, label }) => ({
        value,
        label,
        name: FilterTypes.BRAND,
        title: "Бренд",
      })),
      ...modelFilter.value.map(({ value, label }) => ({
        value,
        label,
        name: FilterTypes.MODEL,
        title: "Модель",
      })),
      ...statusFilter.value.map(({ value, label }) => ({
        value,
        label,
        name: FilterTypes.STATUS,
        title: "Статус",
      })),
      ...stockFilter.value.map(({ value, label }) => ({
        value,
        label,
        name: FilterTypes.STOCK,
        title: "Наличие",
      })),
    ].filter(isNotNullish)
  );

  return {
    brandFilter,
    brandOptionsList,
    modelFilter,
    modelOptionsList: filteredModelList,
    statusFilter,
    statusOptionsList,
    stockFilter,
    stockOptionsList: stockFilterOptions,
    tagRemovalHandler,
    tags: tags,
    filterParams,
  };
}
