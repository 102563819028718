import { ColumnDefinition } from "@tager/admin-ui";

import { BrandInterface } from "@/typings/model";
import { getBrandFormUrl } from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<BrandInterface>> = [
  {
    id: 1,
    name: "API ID",
    field: "apiId",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Name",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getBrandFormUrl({ brandId: row.id }),
      },
      paramList: [
        {
          name: "Показывать в каталоге",
          value: row.showInCatalog ? "Да" : "Нет",
        },
        {
          name: "Kufar",
          value: row.kufar ? `${row.kufar.id} (${row.kufar.name})` : "-",
        },
      ],
    }),
  },
  {
    id: 3,
    name: "Logo",
    field: "logoUrl",
    type: "image",
    style: { width: "150px", textAlign: "center" },
    headStyle: { width: "150px", textAlign: "center" },
  },
  {
    id: 4,
    name: "Склад - Новые",
    field: "stock-new",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 5,
    name: "Склад - АМП",
    field: "stock-amp",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "Склад - Электро",
    field: "stock-electric",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 7,
    name: "Склад - Коммерческие",
    field: "stock-commercial",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 8,
    name: "Склад - Гибриды",
    field: "stock-hybrid",
    style: { width: "180px", textAlign: "left" },
    headStyle: { width: "180px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 9,
    name: "",
    field: "relations",
    style: { width: "250px", textAlign: "left" },
    headStyle: { width: "250px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 10,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];
