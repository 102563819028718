import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type WarrantyLandingFormValues = {
  warrantyTitle?: string;
  warrantyText?: string;
  warrantyImage?: Nullable<SingleFileInputValueType>;
};

export const convertWarrantyValueToFormValues = (
  data: any
): WarrantyLandingFormValues => ({
  warrantyTitle: data.warrantyTitle,
  warrantyText: data.warrantyText,
  warrantyImage: data.warrantyImage
    ? { id: createId(), file: data.warrantyImage }
    : null,
});

export type WarrantyLandingValue = {
  warrantyTitle?: string;
  warrantyText?: string;
  warrantyImage?: string | null;
  warrantyItems?: Array<{
    param: string;
    value: string;
  }>;
};

export const convertWarrantyFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  warrantyItemsField: RepeaterField
): WarrantyLandingValue => ({
  warrantyTitle: formValues.warrantyTitle,
  warrantyText: formValues.warrantyText,
  warrantyImage: formValues.warrantyImage?.file.id || null,
  warrantyItems: warrantyItemsField.value.map((item) => ({
    param: String(item.value[0].value),
    value: String(item.value[1].value),
  })),
});

export const WarrantyItemsFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Элементы",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "param",
      type: "STRING",
      label: "Параметр",
    },
    {
      name: "value",
      type: "TEXT",
      label: "Значение",
    },
  ],
};

export const createWarrantyItemsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      WarrantyItemsFieldConfig,
      data.warrantyItems?.map((item: any) => [
        {
          name: "param",
          value: item.param,
        },
        {
          name: "value",
          value: item.value,
        },
      ]) || []
    )
  );
