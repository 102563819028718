import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3b0f2ba6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relations" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountButton = _resolveComponent("CountButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TableCellStock = _resolveComponent("TableCellStock")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, { title: "Бренды" }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        "cell(relations)": _withCtx(({ row }) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getModelListUrl(),
              query: { 'filter[brand]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CountButton, {
                  variant: "outline-secondary",
                  count: row.modelsCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Модели ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getGenerationListUrl(),
              query: { 'filter[brand]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CountButton, {
                  variant: "outline-secondary",
                  count: row.generationsCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Поколения ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"]),
            _createVNode(_component_router_link, {
              to: {
              path: _ctx.getComplectationListUrl(),
              query: { 'filter[brand]': row.id },
            }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CountButton, {
                  variant: "outline-secondary",
                  count: row.complectationsCount,
                  class: "margin-right"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Комплектации ")
                  ]),
                  _: 2
                }, 1032, ["count"])
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        "cell(stock-new)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockNew
          }, null, 8, ["model"])
        ]),
        "cell(stock-amp)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockAmp
          }, null, 8, ["model"])
        ]),
        "cell(stock-electric)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockElectric
          }, null, 8, ["model"])
        ]),
        "cell(stock-commercial)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockCommercial
          }, null, 8, ["model"])
        ]),
        "cell(stock-hybrid)": _withCtx(({ row }) => [
          _createVNode(_component_TableCellStock, {
            model: row.stockHybrid
          }, null, 8, ["model"])
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Редактировать",
            disabled: _ctx.isBusy(),
            href: _ctx.getBrandFormUrl({ brandId: row.id })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "href"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }))
}