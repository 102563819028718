<template>
  <div class="stock">
    <div v-if="model.quantity > 0">
      <span class="stock-title">{{ model.quantity }} авто</span>
      <span v-if="model.minPrice || model.maxPrice" class="stock-price">
        <span v-if="model.minPrice !== null"
          >от {{ formatPrice(model.minPrice) }} BYN</span
        >
        <span v-if="model.maxPrice !== null"
          >до {{ formatPrice(model.maxPrice) }} BYN</span
        >
      </span>
      <div v-if="model.url" class="stock-link">
        <a :href="model.url" target="_blank">Склад</a>
      </div>
    </div>
    <span v-else> - </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { StockData } from "@/typings/model";

export default defineComponent({
  name: "TableCellStock",
  props: {
    model: {
      type: Object as PropType<StockData>,
      default: null,
    },
  },
  setup() {
    const formatPrice = (num: number) =>
      String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ");

    return {
      formatPrice,
    };
  },
});
</script>

<style scoped lang="scss">
.stock {
  text-align: center;

  .stock-name {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .stock-title {
    display: block;
    font-size: 110%;
    font-weight: bold;
  }

  .stock-price {
    display: block;
    margin-top: 5px;
    font-size: 80%;
    span {
      display: block;
    }
  }

  .stock-link {
    margin-top: 5px;

    a {
      color: #007bff;
    }
  }
}
</style>
