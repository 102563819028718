<template>
  <Page title="Модели">
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #filters>
        <AdvancedSearch :tags="tags" @click:tag="tagRemovalHandler">
          <div class="row-cols-2">
            <FormFieldMultiSelect
              v-model:selected-options="brandFilter"
              :options="brandOptionsList"
              name="brandFilter"
              :searchable="true"
              label="Бренд"
            />
            <FormFieldMultiSelect
              v-model:selected-options="stockFilter"
              :options="stockOptionsList"
              name="stock"
              label="Наличие"
            />
          </div>
        </AdvancedSearch>
      </template>

      <template #cell(stock-new)="{ row }">
        <TableCellStock :model="row.stockNew" />
      </template>

      <template #cell(stock-amp)="{ row }">
        <TableCellStock :model="row.stockAmp" />
      </template>

      <template #cell(stock-electric)="{ row }">
        <TableCellStock :model="row.stockElectric" />
      </template>

      <template #cell(stock-commercial)="{ row }">
        <TableCellStock :model="row.stockCommercial" />
      </template>

      <template #cell(stock-hybrid)="{ row }">
        <TableCellStock :model="row.stockHybrid" />
      </template>

      <template #cell(relations)="{ row }">
        <div class="relations">
          <router-link
            :to="{
              path: getGenerationListUrl(),
              query: { 'filter[model]': row.id },
            }"
          >
            <count-button
              variant="outline-secondary"
              :count="row.generationsCount"
              class="margin-right"
            >
              Поколения
            </count-button>
          </router-link>
          <router-link
            :to="{
              path: getComplectationListUrl(),
              query: { 'filter[model]': row.id },
            }"
          >
            <count-button
              variant="outline-secondary"
              :count="row.complectationsCount"
              class="margin-right"
            >
              Комплектации
            </count-button>
          </router-link>
        </div>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import { isEqual, pick } from "lodash";
import { useRoute, useRouter } from "vue-router";

import {
  useDataTable,
  AdvancedSearch,
  DataTable,
  CountButton,
  FormFieldMultiSelect,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getModelList } from "@/services/requests";
import { ModelInterface } from "@/typings/model";
import { getGenerationListUrl, getComplectationListUrl } from "@/utils/paths";
import { COLUMN_DEFS } from "@/views/ModelList/ModelList.helpers";
import { useFetchBrands } from "@/hooks/useFetchBrands";
import { useAdvancedSearch } from "@/views/ModelList/hooks";
import TableCellStock from "@/components/TableCellStock.vue";

export default defineComponent({
  name: "ModelList",
  components: {
    TableCellStock,
    Page,
    AdvancedSearch,
    DataTable,
    CountButton,
    FormFieldMultiSelect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { data: brandList, loading: isBrandsListLoading } = useFetchBrands();

    const {
      brandFilter,
      brandOptionsList,
      stockFilter,
      stockOptionsList,
      filterParams,
      tags,
      tagRemovalHandler,
    } = useAdvancedSearch({
      brandList,
    });

    const {
      fetchEntityList: fetchModelList,
      isLoading: isModelListDataLoading,
      rowData: modelListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ModelInterface>({
      fetchEntityList: (params) =>
        getModelList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Список моделей",
    });

    watch(filterParams, () => {
      if (isBrandsListLoading.value) {
        return;
      }

      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchModelList();
      }
    });

    function isBusy(): boolean {
      return isModelListDataLoading.value || isBrandsListLoading.value;
    }

    const formatPrice = (num: number) =>
      String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ");

    return {
      columnDefs: COLUMN_DEFS,
      rowData: modelListData,
      isRowDataLoading: isModelListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      formatPrice,
      // Advanced search
      brandFilter,
      brandOptionsList,
      stockFilter,
      stockOptionsList,
      tags,
      tagRemovalHandler,

      isBusy,
      getGenerationListUrl,
      getComplectationListUrl,
    };
  },
});
</script>

<style scoped lang="scss">
.relations {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:first-child) {
    margin-top: 10px;
  }
}

.filters {
  display: flex;
  margin: 0 -10px;

  &:not(:first-child) {
    margin-top: 10px;
  }

  .filter {
    padding: 10px 10px 0;
    width: 50%;
    margin: 0;
  }
}
</style>
