import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import { SingleFileInputValueType } from "@tager/admin-ui";
import { createId, Nullable } from "@tager/admin-services";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type SafetyLandingFormValues = {
  safetyTitle?: string;
  safetyText?: string;
  safetyRatingTitle?: string;
  safetyRatingValue?: number;
  safetyRatingLogos?: Array<SingleFileInputValueType>;
  safetyImage?: Nullable<SingleFileInputValueType>;
  safetyVideo?: Nullable<SingleFileInputValueType>;
  safetyVideoUrl?: string;
};

export const convertSafetyValueToFormValues = (
  data: any
): SafetyLandingFormValues => ({
  safetyTitle: data.safetyTitle,
  safetyText: data.safetyText,

  safetyRatingTitle: data.safetyRatingTitle,
  safetyRatingValue: data.safetyRatingValue,
  safetyRatingLogos:
    data.safetyRatingLogos?.map((item: any) => ({
      id: createId(),
      file: item,
    })) || [],

  safetyImage: data.safetyImage
    ? { id: createId(), file: data.safetyImage }
    : null,
  safetyVideo: data.safetyVideo
    ? { id: createId(), file: data.safetyVideo }
    : null,
  safetyVideoUrl: data.safetyVideoUrl,
});

export type SafetyLandingValue = {
  safetyTitle?: string;
  safetyText?: string;
  safetyRatingTitle?: string;
  safetyRatingValue?: number | null;
  safetyRatingLogos?: string[];
  safetyImage?: string | null;
  safetyVideo?: string | null;
  safetyVideoUrl?: string;
  safetyItems?: Array<{
    title: string;
    text: string;
  }>;
};

export const convertSafetyFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  safetyItemsField: RepeaterField
): SafetyLandingValue => ({
  safetyTitle: formValues.safetyTitle,
  safetyText: formValues.safetyText,
  safetyRatingTitle: formValues.safetyRatingTitle,
  safetyRatingValue: formValues.safetyRatingValue
    ? +formValues.safetyRatingValue
    : null,
  safetyRatingLogos:
    formValues.safetyRatingLogos
      ?.map((item) => (item ? item.file.id : ""))
      .filter((item) => !!item) || [],
  safetyImage: formValues.safetyImage ? formValues.safetyImage?.file.id : null,
  safetyVideo: formValues.safetyVideo ? formValues.safetyVideo?.file.id : null,
  safetyVideoUrl: formValues.safetyVideoUrl,
  safetyItems: safetyItemsField.value.map((item) => ({
    title: String(item.value[0].value),
    text: String(item.value[1].value),
  })),
});

export const SafetyItemsFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Элементы",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "TEXT",
      label: "Текст",
    },
  ],
};

export const createSafetyItemsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      SafetyItemsFieldConfig,
      data.safetyItems?.map((item: any) => {
        return [
          {
            name: "title",
            value: item.title,
          },
          {
            name: "text",
            value: item.text,
          },
        ];
      }) || []
    )
  );
