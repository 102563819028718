import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { GenerationLandingValue } from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type MainLandingFormValues = {
  mainBlocksTitle?: string;
  mainBlocksText?: string;
};

export const convertMainValueToFormValues = (
  data: any
): MainLandingFormValues => ({
  mainBlocksTitle: data.mainBlocksTitle,
  mainBlocksText: data.mainBlocksText,
});

export type MainLandingValue = MainLandingFormValues & {
  mainBlocks?: Array<{
    image: string | null;
    title: string;
    text: string;
  }>;
};

export const convertMainFormValuesToValue = (
  formValues: MainLandingFormValues,
  mainBlocksField: RepeaterField
): MainLandingValue => ({
  mainBlocksTitle: formValues.mainBlocksTitle,
  mainBlocksText: formValues.mainBlocksText,
  mainBlocks: mainBlocksField.value.map((item) => ({
    title: String(item.value[0].value),
    text: String(item.value[1].value),
    image: item.value[2].value ? (item.value[2].value as any).file.id : null,
  })),
});

export const MainBlocksFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Основной блок",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "TEXT",
      label: "Текст",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (1230 x 450, 600 x 710, 600 x 340)",
      meta: {
        scenario: "generation-landing",
      },
    },
  ],
};

export const createMainBlocksField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      MainBlocksFieldConfig,
      data.mainBlocks?.map((item: any) => {
        return [
          {
            name: "image",
            value: item.image,
          },
          {
            name: "title",
            value: item.title,
          },
          {
            name: "text",
            value: item.text,
          },
        ];
      }) || []
    )
  );
