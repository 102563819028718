import { computed, ComputedRef, Ref, ref, watch } from "vue";
import { useRoute } from "vue-router";

import {
  FilterTagType,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
} from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { BrandInterface } from "@/typings/model";
import { stockFilterOptions } from "@/views/ModelList/ModelList.helpers";

interface Params {
  brandList: Ref<BrandInterface[]>;
}

interface State {
  brandFilter: Ref<OptionType[]>;
  stockFilter: Ref<OptionType[]>;
  brandOptionsList: ComputedRef<OptionType[]>;
  stockOptionsList: OptionType[];
  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;

  tagRemovalHandler(event: FilterTagType): void;
}

enum FilterTypes {
  BRAND = "brand",
  STOCK = "stock",
}

export function useAdvancedSearch({ brandList }: Params): State {
  const route = useRoute();
  /** Brand **/
  const brandOptionsList = computed<OptionType[]>(() =>
    brandList.value.map(({ id, name }) => ({
      value: String(id),
      label: name,
    }))
  );

  const initialBrandFilter = computed<OptionType[]>(() => {
    const queryValue = getFilterParamAsStringArray(
      route.query,
      FilterTypes.BRAND
    );
    return brandOptionsList.value.filter(({ value }) =>
      queryValue.some((selected) => selected === value)
    );
  });

  const brandFilter = ref<OptionType[]>(initialBrandFilter.value);

  watch(initialBrandFilter, () => {
    brandFilter.value = initialBrandFilter.value;
  });

  /** Stock **/

  const initialStockFilter = computed<OptionType[]>(() => {
    const queryValue = getFilterParamAsStringArray(
      route.query,
      FilterTypes.STOCK
    );

    return stockFilterOptions.filter(({ value }) =>
      queryValue.some((selected) => selected === value)
    );
  });

  const stockFilter = ref<OptionType[]>(initialStockFilter.value);

  watch(initialStockFilter, () => {
    stockFilter.value = initialStockFilter.value;
  });

  /** Params **/

  const filterParams = computed(() => {
    const filters: Record<string, string | string[]> = {
      [FilterTypes.BRAND]: brandFilter.value.map(({ value }) => value),
      [FilterTypes.STOCK]: stockFilter.value.map(({ value }) => value),
    };

    return getFilterParams(filters);
  });

  /** Tag removal handler **/

  function tagRemovalHandler(event: FilterTagType) {
    if (event.name === FilterTypes.BRAND) {
      brandFilter.value = brandFilter.value.filter(
        ({ value }) => value !== event.value
      );
    }
    if (event.name === FilterTypes.STOCK) {
      stockFilter.value = stockFilter.value.filter(
        ({ value }) => value !== event.value
      );
    }
  }

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      ...brandFilter.value.map(({ value, label }) => ({
        value,
        label,
        name: FilterTypes.BRAND,
        title: "Бренд",
      })),
      ...stockFilter.value.map(({ value, label }) => ({
        value,
        label,
        name: FilterTypes.STOCK,
        title: "Наличие",
      })),
    ].filter(isNotNullish)
  );

  return {
    brandFilter,
    brandOptionsList,
    stockFilter,
    stockOptionsList: stockFilterOptions,
    tagRemovalHandler,
    tags: tags,
    filterParams,
  };
}
