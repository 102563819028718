import { createId, Nullable } from "@tager/admin-services";
import { SingleFileInputValueType, OptionType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { ComplectationFullInterface } from "@/typings/model";
import {
  ComplectationSavePayload,
  ComplectationCustomSavePayload,
} from "@/services/requests";

export interface FormValues {
  brand: Nullable<OptionType<number>>;
  model: Nullable<OptionType<number>>;
  generation: Nullable<OptionType<number>>;
  name: string;
  subtitle: string;
  image: Nullable<SingleFileInputValueType>;
}

export function getComplectationFormValues(
  model: Nullable<ComplectationFullInterface>
): FormValues {
  if (!model) {
    return {
      brand: null,
      model: null,
      generation: null,
      name: "",
      subtitle: "",
      image: null,
    };
  }

  return {
    brand: null,
    model: null,
    generation: null,
    name: model.name,
    subtitle: model.subtitle,
    image: model?.image ? { id: createId(), file: model.image } : null,
  };
}

export const COMPLECTATION_ITEMS_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Элементы",
  meta: {
    view: "BLOCK",
    noToggle: true,
    titleFormatter(fields) {
      return String(fields[0].value) || "Категория";
    },
  },
  fields: [
    {
      name: "category",
      type: "STRING",
      label: "Категория",
    },
    {
      name: "items",
      type: "REPEATER",
      label: "Элементы",
      meta: {
        view: "TABLE",
        noToggle: true,
      },
      fields: [
        {
          name: "param",
          type: "STRING",
          label: "Параметр",
        },
        {
          name: "value",
          type: "TEXT",
          label: "Значение",
        },
        {
          name: "hint",
          type: "TEXT",
          label: "Подсказка",
        },
      ],
    },
  ],
};

export function convertFormValuesToSavePayload(
  values: FormValues
): ComplectationSavePayload {
  return {
    subtitle: values.subtitle,
    image: values.image?.file?.id || null,
  };
}

export function convertFormValuesToCustomSavePayload(
  values: FormValues,
  itemsField: RepeaterField
): ComplectationCustomSavePayload {
  return {
    generation: values.generation?.value || null,
    name: values.name,
    subtitle: values.subtitle,
    image: values.image?.file?.id || null,
    categories: itemsField.value.map((item) => {
      return {
        name: String(item.value[0].value),
        items: (item.value[1].value as any).map((item: any) => ({
          param: String(item.value[0].value),
          value: String(item.value[1].value),
          hint: String(item.value[2].value),
        })),
      };
    }),
  };
}
