import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/views/GenerationForm/landing/GenerationFormLanding.helpers";

export type GalleryLandingFormValues = {
  galleryTitle?: string;
  galleryText?: string;
};

export const convertGalleryValueToFormValues = (
  data: any
): GalleryLandingFormValues => ({
  galleryTitle: data.galleryTitle,
  galleryText: data.galleryText,
});

export type GalleryLandingValue = GalleryLandingFormValues & {
  galleryItems?: Array<{
    title: string;
    image: string | null;
  }>;
};

export const convertGalleryFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  galleryField: RepeaterField
): GalleryLandingValue => ({
  galleryTitle: formValues.galleryTitle,
  galleryText: formValues.galleryText,
  galleryItems: galleryField.value.map((item) => ({
    title: String(item.value[0].value),
    image: item.value[1].value ? (item.value[1].value as any).file.id : null,
  })),
});

export const GalleryItemsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Картинки",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (600 x 540, 600 x 340, 285 x 340)",
      meta: {
        scenario: "generation-landing",
      },
    },
  ],
};

export const createGalleryItemsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      GalleryItemsConfig,
      data.galleryItems?.map((item: any) => [
        {
          name: "title",
          value: item.title,
        },
        {
          name: "image",
          value: item.image,
        },
      ]) || []
    )
  );
